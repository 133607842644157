import { defineStore } from "pinia";

const authStore = defineStore("general", {
    state: () =>({
        token: null,
        signedUser: null,
        selectedUser: null,
        userId: null,
        tenantId: null,
        tenantName: null,
        tenantPhoneNumber: null,
        tenantDueDate: null,
        role: false
    }),
    getters: {
        isAuthenticated: (state) => !!state.token,
        getToken: (state) => state.token,
        getSignedUser: (state) => state.signedUser,
        getSelectedUser: (state) => state.selectedUser,
        getSelectedUserId: (state) => state.userId,
        getTenantId: (state) => state.tenantId,
        getTenantName: (state) => state.tenantName,
        getTenantPhoneNumber: (state) => state.tenantPhoneNumber,
        getTenantDueDate: (state) => state.tenantDueDate,
        getAssignedRole: (state)=> state.role,
    },
    actions: {
        setToken(token){
            this.token = token;
        },
        setSignedUser(signedUser){
            this.signedUser = signedUser;
        },
        setSelectedUser(selectedUser){
            this.selectedUser = selectedUser;
        },
        setUserId(userId){
            this.userId = userId;
        },
        setTenantId(tenantId){
            this.tenantId = tenantId;
        },
        setTenantName(tenantName){
            this.tenantName = tenantName;
        },
        setTenantPhoneNumber(tenantPhoneNumber){
            this.tenantPhoneNumber = tenantPhoneNumber;
        },
        setTenantDueDate(tenantDueDate){
            this.tenantDueDate = tenantDueDate;
        },
        setAssignedRole(role){
            this.role = role
        }
    }
});

export { authStore };