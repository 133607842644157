import { createApp } from 'vue'
import { loadFonts } from './plugins/webfontloader'
import { createPinia } from 'pinia';
import { authStore } from './store/store';
import vuetify from './plugins/vuetify'
import App from './App.vue';

// import JsonCSV 
import JsonCSV from "vue-json-csv";

// import router from "./router/index.js"
import router from './router';

// load vuetify fonts
loadFonts()

const pinia = createPinia();

createApp(App)
  .use(router)
  .use(pinia)
  .use(vuetify)
  .component("downloadCsv", JsonCSV)
  .mount('#app');

// configure pinia for usage
const useAuthStore = authStore();

// get values from local-storage
const storedToken = localStorage.getItem("token");
const storedIdentifier = localStorage.getItem("identifier");
const userId = localStorage.getItem("userId");
const tenantId = localStorage.getItem("tenantId");
const tenantName = localStorage.getItem("tenantName");
const tenantPhoneNumber = localStorage.getItem("tenantPhoneNumber");
const tenantDueDate = localStorage.getItem("tenantDueDate");

// configure values obtained from local-storage
if (storedToken) {
  useAuthStore.setToken(storedToken);
  useAuthStore.setSignedUser(storedIdentifier);
  useAuthStore.setUserId(userId);
  useAuthStore.setTenantId(tenantId);
  useAuthStore.setTenantName(tenantName);
  useAuthStore.setTenantPhoneNumber(tenantPhoneNumber);
  useAuthStore.setTenantDueDate(tenantDueDate);
}
