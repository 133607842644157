import { createWebHistory, createRouter } from "vue-router";
// import LoginView from "../views/LoginView";
// import UsersView from "../views/UsersView";
// import TenantsView from "../views/TenantsView";
// import Dashboard from "@/components/dashboard/Dashboard.vue";
// import UserVue from "@/components/users/UserVue.vue";
// import TenantDetails from "@/components/tenants/TenantDetails.vue";
// import EstateView from "../views/EstatesView";
// import NotFound from "../components/notfound/NotFound.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        // {
        //     path: "/dashboard",
        //     name: "dashboard",
        //     component: Dashboard,
        //     meta: {
        //         requiresAuth: true
        //     }
        // },
        {
            path: "/",
            name: "login",
            component: () => import ("../views/LoginView")
        },
        {
            path: "/users",
            name: "users",
            component: () => import("../views/UsersView"),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/user",
            name: "UserDetails",
            component: () => import("@/components/users/UserVue.vue"),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/tenants",
            name: "tenants",
            component: () => import("../views/TenantsView"),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/tenant",
            name: "TenantDetails",
            component: () => import("@/components/tenants/TenantDetails.vue"),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/estates",
            name: "estates",
            component: () => import("../views/EstatesView")
        },
        {
            path: "/:pathMatch(.*)*",
            name: "NotFound",
            component: () => import("../components/notfound/NotFound.vue")
        },
    ]
});

// authentication process
router.beforeEach((to, from, next) =>{
    if(to.matched.some((record)=> record.meta.requiresAuth)){
        if(!isAuthenticated()){
            // redirect to login page if not authenticated
            next({ name: "login" });
        }else{
            // proceed if authenticated
            next();
        }
    }else{
        // proceed to requested page
        next();
    }
});

const isAuthenticated = () =>{
    try {
        // check for token existence
        const token = localStorage.getItem('token');

        // preview token
        // console.log(token);
        return token !== null
    } catch (error) {
        console.error("No token found",error);
    }
};

export default router;